<template>
  <v-theme-provider>
    <base-section id="boards" space="10">
      <v-container>
        <v-row justify="center">
          <v-col cols="12">
            <base-section-heading title="Komisaris dan Direksi" />
          </v-col>
          <v-col cols="8">
            <v-text-field
              v-model="search"
              placeholder="Ketik kata kunci disini..."
              prepend-inner-icon="mdi-magnify"
              filled
              rounded
              dense
              class="centered-icon"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <base-body space="0">
              <v-container fluid>
                <v-row dense>
                  <v-col
                    v-for="(card, index) in filteredCards"
                    :key="card.name"
                    cols="12"
                    md="3"
                  >
                    <v-card
                      @click="PersonDetail(card.src, card.name, card.detail, card.title)"
                      class="card-img"
                    >
                      <div>
                        <v-img
                          :src="require(`@/assets/${card.src}`)"
                          height="380px"
                          class="img-preview"
                        ></v-img>
                      </div>

                      <v-card-title class="profile-name">{{
                        card.name
                      }}</v-card-title>
                      <v-card-subtitle style="color: black">
                        {{ card.title }}
                      </v-card-subtitle>
                      <v-expand-transition>
                        <div v-show="show[index]">
                          <v-divider></v-divider>
                          <v-card-text>{{ card.detail }}</v-card-text>
                        </div>
                      </v-expand-transition>
                    </v-card>
                  </v-col>
                </v-row>
                <v-dialog
                  v-model="dialog.detail"
                  max-width="90%"
                  persistent
                  style="border-radius: 20px"
                >
                  <v-card>
                    <v-card-actions style="height: 80px">
                      <span class="primary--text">{{detail.title}}</span>
                      <v-spacer></v-spacer>
                      <v-btn
                        rounded
                        outlined
                        color="grey"
                        dark
                        @click="dialog.detail = false"
                        class="mr-5"
                      >
                        X
                      </v-btn>
                    </v-card-actions>
                    <v-divider></v-divider>
                    <v-progress-linear
                      indeterminate
                      v-if="loading.detail"
                    ></v-progress-linear>
                    <v-container>
                      <v-row class="mx-2">
                        <v-col cols="12" sm="3" class="text-center mb-4">
                          <v-avatar size="165">
                            <v-img
                              contain
                              :src="
                                detail.img
                                  ? require(`@/assets/thumb-${detail.img}`)
                                  : require('@/assets/user-no-photo.png')
                              "
                              :alt="
                                detail.img ? detail.img : 'Placeholder Image'
                              "
                              :style="{ border: '1px solid #ccc' }"
                            />
                          </v-avatar>
                        </v-col>
                        <v-col
                          cols="12"
                          sm="9"
                          class="text-h4 display-flex justify-center align-center text-center-sm"
                          style="align-self: center; color: #114e87"
                        >
                          {{ detail.name }}
                        </v-col>
                        <v-col cols="12">
                          <div
                            class="display-flex text-justify"
                            v-html="detail.description"
                          ></div>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card>
                </v-dialog>
              </v-container>
            </base-body>
          </v-col>
          <!-- <v-col cols="12">
            <base-section-heading title="Divisi" />
          </v-col>
          <v-col
          v-for="(division, i) in divisions"
          :key="i"
          cols="4"
          md="4"
        >
          <v-card
            color="secondary"
            class="mx-auto"
          >
            <v-card-item>
              <div>
                <div class="text-overline mb-1 text-center white--text">
                  {{ division }}
                </div>
              </div>
            </v-card-item>

            <v-card-actions>
              <v-btn>
                Button
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col> -->
        </v-row>
      </v-container>
    </base-section>
  </v-theme-provider>
</template>

<script>
//import Avatar from "../../components/base/Avatar.vue";
export default {
  //components: { Avatar },
  name: "SectionBoards",
  data() {
    return {
      search: "", // Data untuk menyimpan nilai pencarian
      show: [], // array untuk menyimpan nilai show untuk setiap kartu
      detailShow: [],
      transition: [],
      dialog: {
        detail: false,
      },
      loading: {
        detail: false,
      },
      detail: {
        name: "",
        img: "",
        description: "",
      },
      cards: [
        {
          name: "Hyacintus Henri Djantoko",
          title: "President Commissioner",
          src: "hyacintus-henry-djantoko.png",
          thumbnail: "thumb-hyacintus-henry-djantoko.png",
          detail:
            `<div>Warga Negara Indonesia, lahir pada tahun 1954. Memperoleh gelar Sarjana dari Sekolah Tinggi Perkebunan Yogyakarta. Memiliki sertifikasi keahlian di bidang Manajemen Risiko dan Pialang Asuransi diantaranya adalah CRGP, QRGP, dan Ahli Pialang Asuransi dan Reasuransi Indonesia.<br><br>
            Saat ini menjabat sebagai Komisaris Independen PT. Lippo General Indonesia (LGI) sejak tahun 2023. Sebelumnya menjabat sebagai Presiden Komisaris PT. AON Reinsurance Broker Indonesia (2016-2023), Direktur PT. AON Indonesia (2009-2023), Sr. Associate Director PT. Asuransi AIG Life (2005-2009), Associate Director PT. Asuransi AIG Lippo Life (2000-2005), dan berbagai Posisi Strategis di PT. Asuransi Jiwa Lippo Life (1984-1999). Selain itu, beliau juga pernah menjabat sebagai Ketua Umum Asosiasi Dana Pensiun Lembaga Keuangan (2002-2006) dan aktif sebagai pembicara di berbagai seminar dan workshop mengenai asuransi kesehatan, asuransi jiwa khususnya program employee benefit dan program dana pensiun.<br><br>
            Lulus fit and proper test OJK sebagai Komisaris Utama PT. Orion Penjaminan Indonesia sesuai SK OJK No. KEP-201/PD.02/2023 tanggal 20 November 2023.<br>
          </div>`,
        },
        {
          name: "Josh Hidayat",
          title: "Independent Commisioner",
          src: "josh-hidayat.png",
          thumbnail: "thumb-josh-hidayat.png",
          detail:
            `<div>Warga Negara Indonesia, lahir pada tahun 1967. Memperoleh gelar Sarjana Ekonomi dan Manajemen di Universitas Kristen Indonesia. Memiliki sertifikasi keahlian di bidang Manajemen Risiko, Asuransi dan Pialang Asuransi diantaranya adalah QRGP, Certified Insurance Practicioner ANZIIF, Ahli Pialang Asuransi Indonesia dan Certified Indonesia Insurance Broker.<br><br>
            Saat ini menjabat sebagai Komisaris Independen PT. MNC Asuransi Indonesia sejak 2022 dan Direktur Utama PT. Solusiutama Tekno Broker Asuransi sejak 2022. Sebelumnya menjabat sebagai Direktur di berbagai Perusahaan Pialang Asuransi diantaranya adalah PT. Asuransi Sahabat Artha Proteksi (2018-2021), PT. AON Indonesia (2015-2018), PT. Marsh Re Indonesia (2013-2015), PT. Marsh Indonesia (2011-2012), Country Head Indonesia Willis Re Singapore (2010-2011), dan PT. Willis Indonesia (2002-2008).<br><br>
Lulus fit and proper test OJK sebagai Komisaris Independen PT. Orion Penjaminan Indonesia sesuai SK OJK No. 194/PD.02/2023 tanggal 16 November 2023.<br>
</div>`,
        },
        {
          name: "Ichsan Mufti",
          title: "President Director",
          src: "ichsan-mufti.png",
          thumbnail: "thumb-ichsan-mufti.png",
          detail:
            `<div>Warga Negara Indonesia, lahir pada tahun 1983. Memperoleh gelar Sarjana Ekonomi (Akuntansi) dari STIE YKPN Yogyakarta, Profesi Akuntan (Ak.CA) dan Magister (M.Si) Akuntansi dari Universitas Sumatera Utara. Memiliki sertifikasi profesi di bidang Manajemen Risiko dan Penjaminan diantaranya adalah Certified Risk Governance Professional (CRGP), sebagai Tenaga Ahli Penjaminan dan Asesor Kompetensi di LSP Penjaminan.<br><br>
            Berkarir dari jabatan Kepala Bagian hingga Kepala Divisi di PT. Penjaminan Jamkrindo Syariah sejak 2015 s/d 2023. Sebelumnya beliau juga berpengalaman di Lembaga Keuangan Bank dan Non Bank yaitu PT. State Bank of India Indonesia, PT. Bank Mandiri, PT. Bank Syariah Mandiri, PT. Permodalan Nasional Madani dan PT. Bank Muamalat Indonesia.<br><br>
            Lulus Fit and Proper Test OJK sebagai Direktur Utama PT. Orion Penjaminan Indonesia sesuai SK OJK No. KEP-193/PD.02/2023 tanggal 16 November 2023.<br>
            </div>`,
        },
        {
          name: "Rusiandi",
          title: "Director",
          src: "rusiandi.png",
          thumbnail: "thumb-rusiandi.png",
          detail:
            `<div>Warga Negara Indonesia, lahir pada tahun 1978. Memperoleh gelar Sarjana Sains (S.Si) di Bidang Matematika dari Universitas Padjadjaran. Memiliki sertifikasi keahlian di bidang Manajemen Risiko, Asuransi dan Penjaminan diantaranya adalah Certified Risk Governance Professional (CRGP), Ahli Asuransi Indonesia Kerugian (AAIK) dan Tenaga Ahli Penjaminan.<br><br>
              Sebelumnya berkarir di PT. Penjaminan Jamkrindo Syariah dengan jabatan terakhir sebagai Kepala Divisi Teknik Penjaminan (2017-2023). Sebelum berkarir di Perusahaan Penjaminan, beliau juga berpengalaman di Perasuransian baik Asuransi Kerugian maupun Jiwa diantaranya adalah PT. Asuransi Takaful Umum (2003 - 2017), PT. Asuransi Takaful Keluarga (2017) dan PT. Jaya Proteksi Takaful (2016).<br><br>
              Lulus fit and proper test OJK sebagai Direktur PT. Orion Penjaminan Indonesia sesuai SK OJK No. KEP-192/PD.02/2023 tanggal 16 November 2023.<br>
            </div>`,
        },
      ],
      divisions: ['Audit & Compliance','Business','Operational','HC, GA & Corcom', 'Finance & Risk Management','Information Technology']
    };
  },
  computed: {
    // Filter kartu berdasarkan nilai pencarian
    filteredCards() {
      return this.cards.filter((card) => {
        // Memeriksa apakah nama atau detail kartu cocok dengan nilai pencarian
        return (
          card.name.toLowerCase().includes(this.search.toLowerCase()) ||
          card.detail.toLowerCase().includes(this.search.toLowerCase())
        );
      });
    },
  },
  methods: {
    toggleCard(index) {
      // Mengganti nilai show untuk kartu dengan indeks yang diberikan
      this.$set(this.show, index, !this.show[index]);
    },
    toggleDetail(index) {
      // Mengganti nilai show detail untuk kartu dengan indeks yang diberikan
      this.$set(this.detailShow, index, !this.detailShow[index]);
    },
    zoomIn(index) {
      // Mengubah nilai efek grayscale dan zoom saat hover
      this.$set(this.grayscale, index, "grayscale(0%)");
      this.$set(this.zoom, index, "scale(1.2)");
    },
    zoomOut(index) {
      // Mengubah nilai efek grayscale dan zoom saat hover
      this.$set(this.grayscale, index, "grayscale(100%)");
      this.$set(this.zoom, index, "scale(1)");
    },
    PersonDetail(image, name, desc, title) {
      this.dialog.detail = true;
      this.detail.img = image;
      this.detail.name = name;
      this.detail.description = desc;
      this.detail.title = title;
    },
  },
  created() {
    // Inisialisasi nilai show untuk setiap kartu menjadi false
    // this.cards.forEach(() => {
    //   this.show.push(false);
    //   this.detailShow.push(false);
    //   this.grayscale.push("grayscale(100%)");
    //   this.zoom.push("scale(1)");
    //   this.transition.push("0.3s ease-in-out;");
    // });
  },
};
</script>

<style scoped>
.image-container {
  filter: grayscale(100%);

  overflow: hidden;
  cursor: pointer;
  background-color: #fff;
  -webkit-box-shadow: -5px 7px 130px -20px rgba(0, 0, 0, 0.15);
  box-shadow: -5px 7px 130px -20px rgba(0, 0, 0, 0.15);
  height: 100%;
  position: relative;
}
.img-preview:hover {
  -webkit-transform: scale(1.5);
  transform: scale(1.2);
  transition: 0.3s ease-in-out;
  -webkit-transition: 0.3s ease-in-out;
}
.card-img {
  border-radius: 12px;
}

.profile-name {
  margin-bottom: 5px;
  font-family: "Reckless";
  font-size: 17px;
  font-weight: bold;
  line-height: 35px;
  color: #0c4f88;
}
.text-center-sm {
  @media (max-width: 600px) {
    text-align: center;
  }
}
</style>
